import React from "react";
import theme from "theme";
import { Theme, Text, Box, List, Strong, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"terms-and-conditions"} />
		<Helmet>
			<title>
				Shenzhen Starnoon Technology Co., LTD
			</title>
			<meta name={"description"} content={"Revitalize Your Space - Where Cleanliness Meets Excellence"} />
			<meta property={"og:title"} content={"Shenzhen Starnoon Technology Co., LTD"} />
			<meta property={"og:description"} content={"Revitalize Your Space - Where Cleanliness Meets Excellence"} />
			<meta property={"og:image"} content={"https://fraiveckonis.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://fraiveckonis.com/img/shenzhen.jpeg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://fraiveckonis.com/img/shenzhen.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://fraiveckonis.com/img/shenzhen.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://fraiveckonis.com/img/shenzhen.jpeg"} />
			<link rel={"apple-touch-startup-image"} href={"https://fraiveckonis.com/img/shenzhen.jpeg"} />
			<meta name={"msapplication-TileImage"} content={"https://fraiveckonis.com/img/shenzhen.jpeg"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h1" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Terms and Conditions
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				Welcome to fraiveckonis.com!
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				These terms and conditions outline the rules and regulations for the use of The Fite-Late Social's Website, located at fraiveckonis.com.
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				By accessing this website we assume you accept these terms and conditions. Do not continue to use fraiveckonis.com if you do not agree to take all of the terms and conditions stated on this page.
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company's terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client's needs in respect of provision of the Company's stated services, in accordance with and subject to, prevailing law of gb. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.
			</Text>
			<Text
				as="h3"
				font="--lead"
				margin="10px 0 0 0"
				text-transform="uppercase"
				letter-spacing="5px"
			>
				Cookies
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				We employ the use of cookies. By accessing fraiveckonis.com, you agreed to use cookies in agreement with the The Fite-Late Social's Privacy Policy.{" "}
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.
			</Text>
			<Text
				as="h3"
				font="--lead"
				margin="10px 0 0 0"
				text-transform="uppercase"
				letter-spacing="5px"
			>
				License
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				Unless otherwise stated, The Fite-Late Social and/or its licensors own the intellectual property rights for all material on fraiveckonis.com. All intellectual property rights are reserved. You may access this from fraiveckonis.com for your own personal use subjected to restrictions set in these terms and conditions.
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				You must not:
			</Text>
			<List text-align="left">
				<Box font="--lead">
					Republish material from fraiveckonis.com
				</Box>
				<Box font="--lead">
					Sell, rent or sub-license material from fraiveckonis.com
				</Box>
				<Box font="--lead">
					Reproduce, duplicate or copy material from fraiveckonis.com
				</Box>
				<Box font="--lead">
					Redistribute content from fraiveckonis.com
				</Box>
			</List>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				This Agreement shall begin on the date hereof.{" "}
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. The Fite-Late Social does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of The Fite-Late Social,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, The Fite-Late Social shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				The Fite-Late Social reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				You warrant and represent that:
			</Text>
			<List text-align="left">
				<Box font="--lead">
					You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;
				</Box>
				<Box font="--lead">
					The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;
				</Box>
				<Box font="--lead">
					The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy
				</Box>
				<Box font="--lead">
					The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.
				</Box>
			</List>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				You hereby grant The Fite-Late Social a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.
			</Text>
			<Text
				as="h3"
				font="--lead"
				margin="10px 0 0 0"
				text-transform="uppercase"
				letter-spacing="5px"
			>
				Hyperlinking to our Content
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				The following organizations may link to our Website without prior written approval:
			</Text>
			<List text-align="left">
				<Box font="--lead">
					Government agencies;
				</Box>
				<Box font="--lead">
					Search engines;
				</Box>
				<Box font="--lead">
					News organizations;
				</Box>
				<Box font="--lead">
					Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and
				</Box>
				<Box font="--lead">
					System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.
				</Box>
			</List>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				We may consider and approve other link requests from the following types of organizations:
			</Text>
			<List text-align="left">
				<Box font="--lead">
					commonly-known consumer and/or business information sources;
				</Box>
				<Box font="--lead">
					dot.com community sites;
				</Box>
				<Box font="--lead">
					associations or other groups representing charities;
				</Box>
				<Box font="--lead">
					online directory distributors;
				</Box>
				<Box font="--lead">
					internet portals;
				</Box>
				<Box font="--lead">
					accounting, law and consulting firms; and
				</Box>
				<Box font="--lead">
					educational institutions and trade associations.
				</Box>
			</List>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of The Fite-Late Social; and (d) the link is in the context of general resource information.
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site.
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to The Fite-Late Social. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				Approved organizations may hyperlink to our Website as follows:
			</Text>
			<List text-align="left">
				<Box font="--lead">
					By use of our corporate name; or
				</Box>
				<Box font="--lead">
					By use of the uniform resource locator being linked to; or
				</Box>
				<Box font="--lead">
					By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party's site.
				</Box>
			</List>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				No use of The Fite-Late Social's logo or other artwork will be allowed for linking absent a trademark license agreement.
			</Text>
			<Text
				as="h3"
				font="--lead"
				margin="10px 0 0 0"
				text-transform="uppercase"
				letter-spacing="5px"
			>
				iFrames
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.
			</Text>
			<Text
				as="h3"
				font="--lead"
				margin="10px 0 0 0"
				text-transform="uppercase"
				letter-spacing="5px"
			>
				Content Liability
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.
			</Text>
			<Text
				as="h3"
				font="--lead"
				margin="10px 0 0 0"
				text-transform="uppercase"
				letter-spacing="5px"
			>
				Reservation of Rights
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it's linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.
			</Text>
			<Text
				as="h3"
				font="--lead"
				margin="10px 0 0 0"
				text-transform="uppercase"
				letter-spacing="5px"
			>
				Removal of links from our website
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.
			</Text>
			<Text
				as="h3"
				font="--lead"
				margin="10px 0 0 0"
				text-transform="uppercase"
				letter-spacing="5px"
			>
				Disclaimer
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:
			</Text>
			<List text-align="left">
				<Box font="--lead">
					limit or exclude our or your liability for death or personal injury;
				</Box>
				<Box font="--lead">
					limit or exclude our or your liability for fraud or fraudulent misrepresentation;
				</Box>
				<Box font="--lead">
					limit any of our or your liabilities in any way that is not permitted under applicable law; or
				</Box>
				<Box font="--lead">
					exclude any of our or your liabilities that may not be excluded under applicable law.
				</Box>
			</List>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.
			</Text>
			<Text
				as="p"
				font="--lead"
				margin="40px 0 20px 0"
				text-align="left"
				width="100%"
			>
				<Strong>
					Shenzhen Kunxiang Bihai Technology Co., Ltd.
				</Strong>
			</Text>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});